import(/* webpackMode: "eager", webpackExports: ["MobileNavigation"] */ "/app/apps/web/src/app/[locale]/(admin)/mobile-navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProBadge"] */ "/app/apps/web/src/app/[locale]/(admin)/pro-badge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/app/apps/web/src/app/[locale]/(admin)/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PayWallDialog"] */ "/app/apps/web/src/components/pay-wall-dialog.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
